import { useAppLevel } from "@/apps/util/appLevelContext"
import {
  ProductAppKind,
  useAddAppMutation,
} from "@/apps/util/hooks/__generated__/useAddAppMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { useCallback } from "react"
import { graphql } from "react-relay"

function useAddApp() {
  const activeOrganization = useActiveOrganization()!
  const { product, navSectionId, navFolderId } = useAppLevel()

  const addApp = Relay.useAsyncMutation<useAddAppMutation>(
    graphql`
      mutation useAddAppMutation(
        $input: CreateProductAppInput!
        $isCurriculumApp: Boolean!
        $isEventsApp: Boolean!
        $kind: ProductAppKind!
        $isOrgTopLevel: Boolean!
      ) {
        response: createProductApp(input: $input) {
          node {
            id
            kind
            status
            ordering
            customAppTitle
            customAppUrl
            showOnDashboard
            product {
              ...MyExperiencesListItemFragment
              ...AppsSidebarList_ProductFragment
              hasProductApp(kind: $kind)
              productApps {
                edges {
                  node {
                    id
                  }
                }
              }
              curriculum @include(if: $isCurriculumApp) {
                id
                ...ContentModuleUtils_useCurriculumModuleIdsFragment
              }
            }
            organization {
              viewerMembership @include(if: $isEventsApp) {
                ...useViewerCanCreateEventsFragment
              }
              ...AppsSidebarList_OrganizationFragment @include(if: $isOrgTopLevel)
            }
            navSection {
              ...AppsSidebarList_NavSectionFragment
            }
            feed {
              id
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  return useCallback(
    async (
      kind: ProductAppKind,
      testid: string,
      customAppTitle?: string | null,
      skipDefaultModule?: boolean | null
    ) => {
      const { response } = await addApp({
        input: {
          productId: product?.id,
          navSectionId,
          organizationId: activeOrganization.id,
          kind,
          showOnDashboard: kind !== "content",
          navFolderId,
          customAppTitle,
          skipDefaultModule,
        },
        isCurriculumApp: kind === "curriculum",
        isEventsApp: kind === "events",
        kind,
        isOrgTopLevel: !product?.id && !navSectionId,
      })

      if (response?.node) {
        displaySuccessToast({
          testid: `${testid}.success-message`,
          message: `App added!`,
        })
        return response.node
      }

      return null
    },
    [addApp, activeOrganization.id, product?.id, navSectionId, navFolderId]
  )
}

export default useAddApp
