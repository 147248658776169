import makeUseStyles from "@assets/style/util/makeUseStyles"
import classNames from "classnames"
import React from "react"
import ContentBox from "../../../components/content-box/ContentBox"

interface AuthenticationFlowContentBoxProps {
  children: React.ReactNode
  customClassName?: string
}

function AuthenticationFlowContentBox({
  children,
  customClassName,
}: AuthenticationFlowContentBoxProps) {
  const classes = useStyles()

  return (
    <ContentBox
      customClassName={classNames(classes.root, customClassName)}
      withShadowDrop={true}
    >
      {children}
    </ContentBox>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6, 3.75, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4.5, 3, 3),
    },
    maxWidth: "90vw",
    width: "540px",
    margin: "auto",
    textAlign: "center",
  },
}))
export default AuthenticationFlowContentBox
