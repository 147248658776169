import {
  SignupUserInput,
  useSignupUserMutation,
} from "@/authentication/signup/util/__generated__/useSignupUserMutation.graphql"
import { AuthUserData, useAuthUser } from "@/core/context/AuthUserContext"
import Relay from "@/relay/relayUtils"
import { graphql } from "relay-runtime"

export function useSignupUser(): (input: SignupUserInput) => Promise<AuthUserData<true>> {
  const mutation = Relay.useAsyncMutation<useSignupUserMutation>(
    graphql`
      mutation useSignupUserMutation($input: SignupUserInput!) {
        signupUser(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `
  )
  const { refreshAuthUser } = useAuthUser()

  async function signup(input: SignupUserInput) {
    const response = await mutation({ input })
    if (response.signupUser.errors?.length)
      throw new Error(response.signupUser.errors[0].message)
    if (!response.signupUser.node?.id) {
      throw new Error("Unable to signup user, please try again.")
    }

    /**
     * Cannot directly query the email back in the signup mutation response due to permission check
     * so we need to refresh the auth user to get the email and other fields
     */
    return refreshAuthUser()
  }

  return signup
}
