import { AppsSidebarAppsDragDrop } from "@/apps/sidebar-item/AppsSidebarDragDropProvider"
import { AppsSidebarList_NavSectionFragment$key } from "@/apps/sidebar-item/__generated__/AppsSidebarList_NavSectionFragment.graphql"
import { AppsSidebarList_OrganizationFragment$key } from "@/apps/sidebar-item/__generated__/AppsSidebarList_OrganizationFragment.graphql"
import { AppsSidebarList_ProductFragment$data } from "@/apps/sidebar-item/__generated__/AppsSidebarList_ProductFragment.graphql"
import { AppsSidebarList_ProductQuery } from "@/apps/sidebar-item/__generated__/AppsSidebarList_ProductQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import useRenderProductAppItem from "@/organization/common/sidebar/my-experiences-list/util/useRenderProductAppItem"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSideBarItemSkeleton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type NavFolderApp = NodeFromConnection<
  AppsSidebarList_ProductFragment$data["productApps"]
>

type Props = TestIDProps & {
  organizationKey?: AppsSidebarList_OrganizationFragment$key | null
  navSectionKey?: AppsSidebarList_NavSectionFragment$key | null
  productId?: GlobalID | null
}

function AppsSidebarList(props: Props) {
  const { organizationKey, navSectionKey, productId, testid = "AppsSidebarList" } = props
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const theme = useTheme()

  const organization = useFragment<AppsSidebarList_OrganizationFragment$key>(
    graphql`
      fragment AppsSidebarList_OrganizationFragment on Organization {
        id
        apps(first: null) @connection(key: "AppsSidebarList_OrganizationFragment__apps") {
          edges {
            node {
              id
              ...useRenderProductAppItemFragment @relay(mask: false)
            }
          }
        }
      }
    `,
    organizationKey || null
  )
  const navSection = useFragment<AppsSidebarList_NavSectionFragment$key>(
    graphql`
      fragment AppsSidebarList_NavSectionFragment on NavSection {
        id
        apps(first: null) @connection(key: "AppsSidebarList_NavSectionFragment__apps") {
          edges {
            node {
              id
              ...useRenderProductAppItemFragment @relay(mask: false)
            }
          }
        }
      }
    `,
    navSectionKey || null
  )
  const { product } = Relay.useSkippableLazyLoadQuery<AppsSidebarList_ProductQuery>(
    graphql`
      query AppsSidebarList_ProductQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            ...AppsSidebarList_ProductFragment @relay(mask: false)
          }
        }
      }
    `,
    { id: productId || "" },
    { skip: !productId }
  )

  const renderProductAppItem = useRenderProductAppItem({ testid, product: activeProduct })
  const permissions = usePermissions(activeProduct || activeOrganization)

  if (productId && !product) return null

  const apps = Relay.connectionToArray(
    product?.productApps || navSection?.apps || organization?.apps
  )
  const canReorder = permissions.has("apps.manage")

  // Check for visible apps here so we can show the empty state is all are hidden. We can't just
  // filter them because that will mess up the drag drop order which needs to include hidden ones
  const hasVisibleApps = apps.some(
    (a) => a.kind !== "chat_channel" || activeOrganization.isChannelsEnabled
  )

  return (
    <AppsSidebarAppsDragDrop
      navSectionId={navSection?.id}
      disabled={!canReorder}
      apps={hasVisibleApps ? apps : []}
      emptyState={getEmptyState()}
      testid={`${testid}.apps`}
    >
      {(app, dragHandleProps, isDragging) =>
        renderProductAppItem({
          app,
          isDragging,
          dragHandleProps,
          children:
            app.kind === "nav_folder" ? (
              <AppsSidebarAppsDragDrop
                navFolderId={app.id}
                disabled={!canReorder}
                apps={Relay.connectionToArray((app as NavFolderApp).folderApps)}
                emptyState={getEmptyState()}
                testid={`NavFolder.${app.customAppTitle}.children`}
              >
                {(folderApp, faDragHandleProps, faIsDragging) =>
                  renderProductAppItem({
                    app: folderApp,
                    dragHandleProps: faDragHandleProps,
                    isDragging: faIsDragging,
                  })
                }
              </AppsSidebarAppsDragDrop>
            ) : null,
        })
      }
    </AppsSidebarAppsDragDrop>
  )

  function getEmptyState() {
    if (!canReorder) return null
    // Add a 1px empty div so apps can still be dragged into it
    if (organization) return <div style={{ height: 1 }} />
    return (
      <DiscoText
        variant={"body-sm"}
        color={theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.300"}
        marginLeft={2}
        marginTop={1}
        marginBottom={1}
      >
        {"No apps here yet."}
      </DiscoText>
    )
  }
}

const useStyles = makeUseStyles({
  skeletonContainer: {
    paddingLeft: 22,
    "& > *": {
      marginTop: 1,
    },
  },
})

function AppsSidebarListSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.skeletonContainer}>
      {range(4).map((i) => (
        <DiscoSideBarItemSkeleton key={i} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton({
  component: AppsSidebarList,
  skeleton: AppsSidebarListSkeleton,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment AppsSidebarList_ProductFragment on Product {
    id
    productApps(first: null)
      @connection(key: "AppsSidebarList_ProductFragment__productApps") {
      edges {
        node {
          id
          kind
          ...useRenderProductAppItemFragment @relay(mask: false)
          folderApps: productApps(first: null)
            @connection(key: "AppsSidebarList_ProductFragment__folderApps") {
            edges {
              node {
                id
                kind
                ...useRenderProductAppItemFragment @relay(mask: false)
              }
            }
          }
        }
      }
    }
  }
`
