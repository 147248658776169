/**
 * @generated SignedSource<<c0ed1cbafa7f7310cb1c161d492c3592>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSearchClientFragment$data = {
  readonly id: string;
  readonly searchConfig: string | null;
  readonly " $fragmentType": "useSearchClientFragment";
};
export type useSearchClientFragment$key = {
  readonly " $data"?: useSearchClientFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSearchClientFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./useSearchClientRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "useSearchClientFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchConfig",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "aa812abc09e2e6365cc4dd51f215ee0b";

export default node;
