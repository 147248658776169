import { WebViewMessage, WebViewMessageType } from "@/apps/util/hooks/useWebViewMessage"
import { useWebViewMessageListener } from "@/apps/util/hooks/useWebViewMessageListener"
import { useHistory } from "react-router-dom"

/**
 * Handle frontend redirect messages from ReactNative
 */
export function useWebViewMessageRedirectListener() {
  const history = useHistory()

  useWebViewMessageListener({
    action: (message: WebViewMessage) => {
      if (message.type !== WebViewMessageType.frontend_redirect) return
      handleFrontendRedirect({
        frontendPath: message.frontendPath,
        params: message.params,
      })
    },
  })

  useWebViewMessageListener({
    action: (message) => {
      if (message.type !== WebViewMessageType.frontend_goback) return
      history.goBack()
    },
  })

  function handleFrontendRedirect(opts: {
    frontendPath?: string
    params?: Record<string, string>
  }) {
    try {
      const path = addParamsToPath(opts.frontendPath ?? location.pathname, opts.params)
      history.push(addViewAsMemberToPath(path))
    } catch (e) {
      // do nothing if path is not valid
      console.error(e)
    }
  }

  function addParamsToPath(frontendPath: string, params?: Record<string, string>) {
    const search = new URLSearchParams(params).toString()
    if (!search) return frontendPath
    return `${frontendPath}?${search}`
  }

  function addViewAsMemberToPath(path: string) {
    if (path.includes("viewAs=member")) return path
    if (path.includes("?")) return `${path}&viewAs=member`
    return `${path}?viewAs=member`
  }
}
