/**
 * @generated SignedSource<<2e5c3040c12189b2751f1ef90668fc01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type FeedAllowPostsFrom = "all" | "admins" | "groups" | "%future added value";
export type ProductAppVisibility = "all" | "admin" | "membership" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEditAppFormStoreFragment$data = {
  readonly id: string;
  readonly customAppTitle: string | null;
  readonly customAppDescription: string | null;
  readonly customAppUrl: string | null;
  readonly showOnDashboard: boolean;
  readonly visibility: ProductAppVisibility;
  readonly navSectionId: string | null;
  readonly linkProductId: string | null;
  readonly curriculum: {
    readonly showCompletedUsers: boolean;
  } | null;
  readonly visibilityGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly visibilityMembers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly feed: {
    readonly name: string;
    readonly allowPostsFrom: FeedAllowPostsFrom;
    readonly allowPostsGroups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    };
    readonly allowPostsMembers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    };
  } | null;
  readonly badge: {
    readonly kind: BadgeKind;
    readonly icon: string | null;
    readonly color: string | null;
    readonly emoji: string | null;
    readonly mediaUrl: string | null;
  };
  readonly " $fragmentType": "useEditAppFormStoreFragment";
};
export type useEditAppFormStoreFragment$key = {
  readonly " $data"?: useEditAppFormStoreFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEditAppFormStoreFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MemberGroupNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MemberGroup",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationMembershipNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMembership",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEditAppFormStoreFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOnDashboard",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "navSectionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkProductId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Curriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showCompletedUsers",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroupNodeConnection",
      "kind": "LinkedField",
      "name": "visibilityGroups",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "visibilityMembers",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowPostsFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupNodeConnection",
          "kind": "LinkedField",
          "name": "allowPostsGroups",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationMembershipNodeConnection",
          "kind": "LinkedField",
          "name": "allowPostsMembers",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "3a2837f0b4d70e4a5efe4595fb920482";

export default node;
