import JoinCommunityFormInvitationSection from "@/authentication/join-community/JoinCommunityFormInvitationSection"
import JoinCommunityFormRegistrationSection from "@/authentication/join-community/JoinCommunityFormRegistrationSection"
import {
  CreateOrganizationMembershipInput,
  JoinCommunityPageMutation,
} from "@/authentication/join-community/__generated__/JoinCommunityPageMutation.graphql"
import { JoinCommunityPageQuery$data } from "@/authentication/join-community/__generated__/JoinCommunityPageQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import CheckoutFormProfileAuthUserSection from "@/product/checkout/form/registration/CheckoutFormProfileAuthUserSection"
import CheckoutFormRegistrationErrorStep from "@/product/checkout/form/registration/CheckoutFormRegistrationErrorStep"
import { GlobalID } from "@/relay/RelayTypes"
import RedirectToSignInLink from "@/user/common/prompt-to-sign-in-link/PromptToSignInLink"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import Form from "@components/form/Form"
import SocialLogin from "@components/social-login/SocialLogin"
import SSOLogin from "@components/sso-login/SSOLogin"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoCheckbox,
  DiscoDivider,
  DiscoFormControl,
  DiscoIcon,
  DiscoLink,
  DiscoText,
  DiscoTextButton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useJoinCommunitySendVerificationEmail } from "../signup/util/useJoinCommunitySendVerificationEmail"
import JoinCommunityFormVerifyEmailSection from "./JoinCommunityFormVerifyEmailSection"

interface Props {
  form: JoinCommunityFormStore
}

export type JoinCommunityFormState = CreateOrganizationMembershipInput & {
  step: "registration" | "success"
  newUser: null | {
    firstName?: string
    lastName?: string
    email?: string
    confirmEmail?: string
  }
  hasAcceptedTerms: boolean
  questions: {
    id: GlobalID
    richEditorBody?: string | null
  }[]
  inviteToken: string | null
  invitation: NonNullable<JoinCommunityPageQuery$data["organization"]>["viewerInvitation"]
  usingEmail: boolean
  hasSeenOnboarding: boolean
  viewerShouldCompleteMemberOnboarding: boolean
  inviteKey: string | null
  isInviteKeyValid: boolean
  needsEmailVerification: boolean
}

export type JoinCommunityFormStore = FormStore<
  JoinCommunityFormState,
  JoinCommunityPageMutation
>

function JoinCommunityForm(props: Props) {
  const { form } = props
  const classes = useStyles()
  const theme = useTheme()

  const { authUser } = useAuthUser()
  const userTimezone = useUserTimezone()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [didntAcceptToc, setDidntAcceptToc] = useState(false)
  const activeOrganization = useActiveOrganization()!

  const memberLabel = useLabel("organization_member")

  const joinCommunitySendVerificationEmail = useJoinCommunitySendVerificationEmail()

  if (activeOrganization.viewerMembership) {
    return (
      <div className={classes.container}>
        <CheckoutFormRegistrationErrorStep
          title={`Join ${activeOrganization.name}`}
          errorMessage={`You are already part of this community.`}
          authUserLabel={"Join as:"}
        />
      </div>
    )
  }

  if (
    activeOrganization.visibility === "private" &&
    !activeOrganization.authProvider?.allowsJitProvisioning &&
    !authUser?.authProviderId
  ) {
    // If private, and SSO is enabled, and JIT provisioning is allowed, they need to get through
    if (form.state.inviteKey) {
      if (!form.state.isInviteKeyValid) {
        // We have an invite key here but it is not valid
        return (
          <div className={classes.container}>
            <CheckoutFormRegistrationErrorStep
              title={`Join ${activeOrganization.name}`}
              errorMessage={`Invitation link is invalid.`}
              authUserLabel={"Join as:"}
            />
          </div>
        )
      }
    } else if (!form.state.invitation) {
      if (form.state.inviteToken) {
        // If we don't have an invitation, but have an invite token then it is either invalid or expired
        // but we want to have them contact an admin for assistance
        return (
          <div className={classes.container}>
            <CheckoutFormRegistrationErrorStep
              title={`Join ${activeOrganization.name}`}
              errorMessage={`Invitation has expired. Please contact a community admin for a new invitation.`}
              authUserLabel={"Join as:"}
            />
          </div>
        )
      }
      return (
        <div className={classes.container}>
          <CheckoutFormRegistrationErrorStep
            icon={<DiscoIcon width={30} height={30} icon={"lock"} />}
            title={`Join ${activeOrganization.name}`}
            errorMessage={`Only invited ${memberLabel.plural} may join this community. Click the invite link in your email for access.`}
            authUserLabel={"Join as:"}
          />
        </div>
      )
    }
  }

  // If we have email verification to do, shortcut here
  if (form.state.needsEmailVerification) {
    return (
      <JoinCommunityFormVerifyEmailSection
        organizationForm={form}
        testid={"JoinCommunityFormVerifyEmailSection"}
      />
    )
  }

  // If we are using the email based signup just shortcut here
  if (form.state.usingEmail) {
    return (
      <Form
        onSubmit={handleSubmit}
        testid={"JoinCommunityForm.form"}
        knownErrorKeys={["firstName", "lastName", "email", "confirmEmail"]}
      >
        <JoinCommunityFormInvitationSection form={form} />

        <DiscoText variant={"heading-sm"} marginTop={3} marginBottom={3}>
          {`Great, let's get you setup!`}
        </DiscoText>

        <JoinCommunityFormRegistrationSection form={form} />

        <div className={classes.joinButton}>
          <DiscoTextButton
            onClick={() => {
              form.state.usingEmail = false
            }}
            variant={"grey"}
            color={theme.palette.type === "dark" ? "groovy.onDark.200" : undefined}
          >
            {"Back to Previous"}
          </DiscoTextButton>
          <DiscoButton
            type={"submit"}
            // disabled={!canSubmitForm()}
            shouldDisplaySpinner={isSubmitting}
            testid={"JoinCommunityForm.submit-button"}
          >
            {"Sign Up"}
          </DiscoButton>
        </div>
      </Form>
    )
  }

  // If we are logged in then let's shortcut here
  if (!form.state.newUser) {
    return (
      <Form onSubmit={handleSubmit} testid={"JoinCommunityForm.form"}>
        <JoinCommunityFormInvitationSection form={form} />

        <DiscoText variant={"heading-sm"} marginTop={3} marginBottom={3}>
          {`Join ${activeOrganization.name}`}
        </DiscoText>
        <CheckoutFormProfileAuthUserSection label={"Join as:"} />
        {/* Standard T&Cs checkbox */}
        {authUser && !authUser.hasAcceptedTerms && (
          <>
            <DiscoFormControl
              errorMessages={
                didntAcceptToc
                  ? ["Please accept the terms and conditions before continuing."]
                  : undefined
              }
              error={didntAcceptToc}
            >
              <DiscoCheckbox
                name={"user-accepts-terms-and-privacy"}
                checked={Boolean(form.state.hasAcceptedTerms)}
                onChange={(v) => {
                  form.state.hasAcceptedTerms = v
                  setDidntAcceptToc(false)
                }}
                data-testid={"JoinCommunityForm.terms-checkbox"}
                label={
                  <DiscoText variant={"body-sm"} color={"text.secondary"}>
                    {"I agree to the Disco "}
                    <DiscoLink to={"https://www.disco.co/terms"} target={"_blank"}>
                      <DiscoText
                        variant={"body-sm"}
                        color={"primary.main"}
                        component={"span"}
                      >
                        {"Terms and Conditions"}
                      </DiscoText>
                    </DiscoLink>
                    {" and "}
                    <DiscoLink
                      to={"https://www.disco.co/privacy-policy"}
                      target={"_blank"}
                    >
                      <DiscoText
                        variant={"body-sm"}
                        color={"primary.main"}
                        component={"span"}
                      >
                        {"Privacy Policy"}
                      </DiscoText>
                    </DiscoLink>
                    {"."}
                  </DiscoText>
                }
              />
            </DiscoFormControl>
          </>
        )}

        {/* Organization level checkboxes */}
        <DiscoFormControl
          errorMessages={form.errorsByField.organizationForm}
          error={Boolean(form.errorsByField.organizationForm)}
        >
          {form.state.questions?.map((question, i) => (
            <DiscoFormControl key={question.id} marginBottom={2}>
              <DiscoCheckbox
                data-testid={`CheckoutFormOrganizationFormSection.checkbox-${i}`}
                label={
                  <DiscoEditor
                    defaultValue={question.richEditorBody}
                    readOnly
                    disableGutter
                  />
                }
                checked={Boolean(form.state.organizationForm![i].answer)}
                onChange={(checked) =>
                  (form.state.organizationForm![i].answer = checked ? "1" : "")
                }
              />
            </DiscoFormControl>
          ))}
        </DiscoFormControl>

        <DiscoButton
          onClick={() => {
            // Check whether we have accepted the TOC
            if (!form.state.hasAcceptedTerms) {
              setDidntAcceptToc(true)
            }
          }}
          type={"submit"}
          shouldDisplaySpinner={isSubmitting}
          testid={"JoinCommunityForm.submit-button"}
          width={"100%"}
        >
          {"Join Community"}
        </DiscoButton>
      </Form>
    )
  }

  // Finally, they are just on the landing page and are not authed so we will ask them how they want to proceed
  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit}
      data-testid={"JoinCommunityForm.form"}
    >
      <JoinCommunityFormInvitationSection form={form} />
      <DiscoText variant={"heading-sm"} marginTop={3} marginBottom={1}>
        {`Create an Account`}
      </DiscoText>
      <DiscoText>{"How would you like to create an account?"}</DiscoText>
      <DiscoDivider marginTop={3} marginBottom={10} />
      {/* Standard T&Cs checkbox */}
      <DiscoFormControl
        errorMessages={
          didntAcceptToc
            ? ["Please accept the terms and conditions before continuing."]
            : undefined
        }
        error={didntAcceptToc}
      >
        <DiscoCheckbox
          name={"user-accepts-terms-and-privacy"}
          checked={Boolean(form.state.hasAcceptedTerms)}
          onChange={(v) => {
            form.state.hasAcceptedTerms = v
            setDidntAcceptToc(false)
          }}
          data-testid={"JoinCommunityForm.terms-checkbox"}
          label={
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {"I agree to the Disco "}
              <DiscoLink to={"https://www.disco.co/terms"} target={"_blank"}>
                <DiscoText variant={"body-sm"} color={"primary.main"} component={"span"}>
                  {"Terms and Conditions"}
                </DiscoText>
              </DiscoLink>
              {" and "}
              <DiscoLink to={"https://www.disco.co/privacy-policy"} target={"_blank"}>
                <DiscoText variant={"body-sm"} color={"primary.main"} component={"span"}>
                  {"Privacy Policy"}
                </DiscoText>
              </DiscoLink>
              {"."}
            </DiscoText>
          }
        />
      </DiscoFormControl>
      {activeOrganization.authProvider ? (
        <SSOLogin
          labelPrefix={"Continue"}
          width={"100%"}
          loginSource={"join_community"}
          redirectDestination={window.location.href}
          onClick={() => {
            if (form.state.hasAcceptedTerms) {
              return true
            }
            setDidntAcceptToc(true)
            return false
          }}
        />
      ) : (
        <>
          <SocialLogin
            onClick={() => {
              if (form.state.hasAcceptedTerms) {
                return true
              }
              setDidntAcceptToc(true)
              return false
            }}
            width={"100%"}
            redirectDestination={window.location.href}
          />
          <DiscoButton
            onClick={() => {
              // Check whether we have accepted the TOC
              if (form.state.hasAcceptedTerms) {
                form.state.usingEmail = true
              } else {
                setDidntAcceptToc(true)
              }
            }}
            shouldDisplaySpinner={isSubmitting}
            testid={"JoinCommunityForm.continue-with-email-button"}
            width={"100%"}
          >
            {"Continue with Email"}
          </DiscoButton>
          <RedirectToSignInLink testid={"JoinCommunityForm.login-link"} />
        </>
      )}
    </form>
  )

  async function handleSubmit() {
    if (isSubmitting) return
    if (!form.state.hasAcceptedTerms) return

    try {
      setIsSubmitting(true)

      // For new users, create their user account and log them in.
      if (form.state.newUser) {
        if (!form.state.newUser.firstName) {
          form.addError({ field: "firstName", message: "This field is required." })
        }
        if (!form.state.newUser.lastName) {
          form.addError({ field: "lastName", message: "This field is required." })
        }
        if (!form.state.newUser.email) {
          form.addError({ field: "email", message: "This field is required." })
        }
        if (form.state.newUser.email !== form.state.newUser.confirmEmail) {
          form.addError({ field: "confirmEmail", message: "Email does not match." })
        }

        if (form.errors.length) {
          return
        }

        if (
          await joinCommunitySendVerificationEmail({
            organizationId: form.state.organizationId,
            timezone: userTimezone,
            email: form.state.newUser.email!,
            firstName: form.state.newUser.firstName!,
            lastName: form.state.newUser.lastName!,
          })
        ) {
          form.state.needsEmailVerification = true
        }
        return
      } else if (!authUser) {
        throw new Error("You must be logged in")
      }

      // Check the questionnaires were answered
      if (form.state.organizationForm?.some((q) => !q.answer)) {
        form.addError({
          field: "organizationForm",
          message: "All conditions above must be accepted.",
        })
        return
      }

      const { didSave, response } = await form.submit({
        organizationId: form.state.organizationId,
        organizationForm: form.state.organizationForm,
        inviteToken: form.state.inviteToken,
        inviteKey: form.state.inviteKey,
      })
      if (didSave) {
        form.state.hasSeenOnboarding = response?.node?.hasSeenOnboarding || false
        form.state.viewerShouldCompleteMemberOnboarding =
          response?.node?.viewerShouldCompleteMemberOnboarding || false
        form.state.step = "success"
      }
    } catch (err) {
      displayErrorToast(err)
    } finally {
      setIsSubmitting(false)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 8, 6, 6),
      height: "100%",
    },
  },
  joinButton: {
    width: "100%",
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(3),
    justifyContent: "flex-end",
  },
}))

export default observer(JoinCommunityForm)
