import { JoinCommunityFormStore } from "@/authentication/join-community/JoinCommunityForm"
import { DiscoDivider, DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { observer } from "mobx-react-lite"

interface Props {
  form: JoinCommunityFormStore
}

const JoinCommunityFormRegistrationSection = observer<Props>((props) => {
  const { form } = props
  const { newUser } = form.state

  if (!newUser) return null

  return (
    <>
      <DiscoText>{"Add your details to get started."}</DiscoText>
      <DiscoDivider marginTop={2} marginBottom={2} />
      <Grid container>
        <Grid item xs={12}>
          <DiscoFormControl
            label={"First Name"}
            errorMessages={form.errorsByField.firstName}
            error={Boolean(form.errorsByField.firstName)}
          >
            <DiscoInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder={"Your first name"}
              name={"first-name"}
              value={newUser.firstName || ""}
              onChange={(e) => (newUser.firstName = e.target.value)}
              data-testid={"JoinCommunityFormRegistrationSection.first-name"}
            />
          </DiscoFormControl>
        </Grid>
        <Grid item xs={12}>
          <DiscoFormControl
            label={"Last Name"}
            errorMessages={form.errorsByField.lastName}
            error={Boolean(form.errorsByField.lastName)}
          >
            <DiscoInput
              placeholder={"Your last name"}
              name={"last-name"}
              value={newUser.lastName || ""}
              onChange={(e) => (newUser.lastName = e.target.value)}
              data-testid={"JoinCommunityFormRegistrationSection.last-name"}
            />
          </DiscoFormControl>
        </Grid>
        <Grid item xs={12}>
          <DiscoFormControl
            label={"Email"}
            errorMessages={form.errorsByField.email}
            error={Boolean(form.errorsByField.email)}
          >
            <DiscoInput
              placeholder={"example@example.org"}
              name={"email"}
              type={"email"}
              value={newUser.email || ""}
              onChange={(e) => (newUser.email = e.target.value)}
              data-testid={"JoinCommunityFormRegistrationSection.email"}
            />
          </DiscoFormControl>
        </Grid>
        <Grid item xs={12}>
          <DiscoFormControl
            label={"Confirm your Email"}
            errorMessages={form.errorsByField.confirmEmail}
            error={Boolean(form.errorsByField.confirmEmail)}
          >
            <DiscoInput
              placeholder={"example@example.org"}
              name={"confirm-email"}
              type={"email"}
              value={newUser.confirmEmail || ""}
              onChange={(e) => (newUser.confirmEmail = e.target.value)}
              data-testid={"JoinCommunityFormRegistrationSection.email-confirm"}
            />
          </DiscoFormControl>
        </Grid>
      </Grid>
    </>
  )
})

export default JoinCommunityFormRegistrationSection
