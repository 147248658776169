import { ProductAppKind } from "@/apps/remove-app-button/__generated__/RemoveAppButtonFragment.graphql"
import { AppIconKind } from "@/apps/util/activeAppModalContext"

export const APP_KIND_LABELS: Record<ProductAppKind | ContentAppKind, string> = {
  posts: "Feed",
  curriculum: "Curriculum",
  events: "Events",
  members: "Members",
  resources: "Resources",
  collection: "Collection",
  chat_channel: "Channels",
  slack: "Slack",
  link: "Link",
  organization_events: "Events",
  organization_members: "Members",
  airtable: "Airtable",
  discord: "Discord",
  figma: "Figma",
  google_doc: "Google Doc",
  google_form: "Google Form",
  miro: "Miro",
  page: "Page",
  typeform: "Typeform",
  whereby: "Whereby",
  custom_embed: "Custom Embed",
  content: "Content",
  nav_folder: "Folder",
  scorm: "SCORM",
  product_link: "Product Link",
  "%future added value": "App",
}

export type ContentAppKind =
  | "page"
  | "airtable"
  | "discord"
  | "figma"
  | "google_doc"
  | "google_form"
  | "miro"
  | "typeform"
  | "whereby"
  | "custom_embed"
  | "scorm"

/** Default icon of an app (as a string) */
export const APP_KIND_ICON: Record<
  Exclude<ProductAppKind | ContentAppKind, "content" | "%future added value">,
  AppIconKind
> = {
  posts: "widget-alt",
  curriculum: "file-alt",
  events: "calendar",
  members: "user",
  resources: "layer",
  collection: "layers-three",
  chat_channel: "chat-alt",
  custom_embed: "code",
  slack: "icon-slack",
  airtable: "icon-airtable",
  discord: "icon-discord",
  figma: "icon-figma",
  google_doc: "icon-google-doc",
  google_form: "icon-google-form",
  miro: "icon-miro",
  typeform: "icon-typeform",
  whereby: "icon-whereby",
  page: "page",
  link: "link",
  organization_events: "calendar",
  organization_members: "user-circle",
  nav_folder: "folder",
  scorm: "icon-scorm",
  product_link: "book",
}

/** AppKinds that can be removed by admins */
export const APP_REMOVABLE_KINDS = new Set<ProductAppKind>([
  "curriculum",
  "events",
  "organization_events",
  "resources",
  "collection",
  "slack",
  "posts",
  "content",
  "link",
  "product_link",
])

/** AppKinds that can be inactivated by admins */
export const CAN_DISABLE_APP_KINDS = new Set<ProductAppKind>([
  "organization_members",
  "members",
])
