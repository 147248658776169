import { WebViewMessage } from "@/apps/util/hooks/useWebViewMessage"
import { useEffect } from "react"

type WebViewMessageHandler = {
  action: (message: WebViewMessage) => void
}

/**
 * Handle receiving messages from ReactNative
 */
export function useWebViewMessageListener(handler: WebViewMessageHandler) {
  /**
   * Listener to receive messages posted from ReactNative
   */
  useEffect(() => {
    return window.addEventListener(
      "message",
      (event) => {
        try {
          const data = JSON.parse(event?.data)
          // Only handle messages that are sent from ReactNative webview
          if (typeof data.isWebViewMessage !== "boolean" || !data.isWebViewMessage) return
          // Only handle messages that have a type
          if (typeof data.type !== "string") return

          const message = data as WebViewMessage

          handler.action(message)
        } catch {
          // do nothing if message is not parsable
        }
      },
      true
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
