import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoText,
  DiscoTextButton,
  DiscoTextSkeleton,
} from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import React from "react"
import { graphql } from "relay-runtime"
import { JoinCommunityFormStore } from "./JoinCommunityForm"
import { JoinCommunityFormVerifyEmailSectionMutation } from "./__generated__/JoinCommunityFormVerifyEmailSectionMutation.graphql"

interface JoinCommunityFormVerifyEmailSectionProps extends TestIDProps {
  organizationForm: JoinCommunityFormStore
}

function JoinCommunityFormVerifyEmailSection({
  testid,
  organizationForm,
}: JoinCommunityFormVerifyEmailSectionProps) {
  const classes = useStyles()
  const { refreshAuthUser } = useAuthUser()

  const form = useFormStore<JoinCommunityFormVerifyEmailSectionMutation>(
    graphql`
      mutation JoinCommunityFormVerifyEmailSectionMutation($input: RegisterUserInput!) {
        response: registerUser(input: $input) {
          node {
            id
            firstName
            lastName
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      email: organizationForm.state.newUser!.email!,
      verificationCode: "",
    }
  )

  return (
    <>
      <Form
        testid={testid}
        classes={{ buttonsRoot: classes.buttonsContainer }}
        buttons={
          <>
            <DiscoTextButton
              onClick={() => {
                organizationForm.state.needsEmailVerification = false
              }}
              className={classes.backToPreviousButton}
            >
              {"Back"}
            </DiscoTextButton>
            <Form.SubmitButton
              testid={`${testid}.button.verify-email`}
              id={`${testid}Form`}
              form={form}
              onClick={handleSubmit}
              className={classes.verifyEmailButton}
              disabled={form.isSubmitting || !form.state.verificationCode}
            >
              {"Verify Email"}
            </Form.SubmitButton>
          </>
        }
      >
        <DiscoText variant={"body-md-600"} marginBottom={2}>
          {"Registration"}
        </DiscoText>
        <DiscoText variant={"body-sm"}>
          {`A verification code has been sent to ${organizationForm.state.newUser!
            .email!}. Enter the code below to complete your registration.`}
        </DiscoText>

        <DiscoDivider marginTop={3} marginBottom={3} />

        <DiscoFormControl
          errorMessages={form.errorsByField.verificationCode}
          marginBottom={0.5}
        >
          <DiscoInput
            fullWidth
            placeholder={"Verification Code"}
            name={"verification-code"}
            value={form.state.verificationCode || ""}
            onChange={(e) => (form.state.verificationCode = e.target.value)}
            data-testid={`${testid}.input.verification-code`}
          />
        </DiscoFormControl>
      </Form>

      <DiscoDivider marginTop={3} marginBottom={2} />
      <DiscoText variant={"body-sm"} align={"center"}>
        {
          "If you didn't receive the code, please go back to verify your information and try again."
        }
      </DiscoText>
    </>
  )

  async function handleSubmit() {
    if (!form.state.verificationCode) {
      form.addError({
        field: "verificationCode",
        message: "Verification code is required",
      })
      return
    }
    const { didSave, response } = await form.submit(form.state)
    if (!didSave || !response?.node) return

    await refreshAuthUser()
    organizationForm.state.usingEmail = false
    organizationForm.state.needsEmailVerification = false
    organizationForm.state.newUser = null
  }
}

const useStyles = makeUseStyles((theme) => ({
  verifyEmailButton: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(3),
    justifyContent: "center",
  },
  backToPreviousButton: {
    color: theme.palette.groovy.grey[400],
  },
  buttonsContainer: {
    marginTop: theme.spacing(1.5),
  },
}))

export const JoinCommunityFormVerifyEmailSectionSkeleton: React.FC = () => {
  return (
    <>
      <DiscoTextSkeleton variant={"body-md-600"} marginBottom={2} width={"45%"} />
      <DiscoTextSkeleton variant={"body-sm"} marginBottom={2} width={"80%"} />
      <Skeleton width={"100%"} height={75} />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<JoinCommunityFormVerifyEmailSectionProps>({
  component: observer(JoinCommunityFormVerifyEmailSection),
  skeleton: JoinCommunityFormVerifyEmailSectionSkeleton,
})
