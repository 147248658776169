/**
 * @generated SignedSource<<a3c04046786455c3f7cd0ba8b2eaa1b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrganizationMembershipInput = {
  organizationId: string;
  organizationForm?: ReadonlyArray<OrganizationQuestionAnswer> | null;
  inviteToken?: string | null;
  skipProductIds?: ReadonlyArray<string> | null;
  isJoiningExperience?: boolean | null;
  inviteKey?: string | null;
};
export type OrganizationQuestionAnswer = {
  questionId: string;
  answer: string;
};
export type JoinCommunityPageMutation$variables = {
  input: CreateOrganizationMembershipInput;
};
export type JoinCommunityPageMutation$data = {
  readonly response: {
    readonly node: {
      readonly hasSeenOnboarding: boolean;
      readonly viewerShouldCompleteMemberOnboarding: boolean;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type JoinCommunityPageMutation = {
  variables: JoinCommunityPageMutation$variables;
  response: JoinCommunityPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSeenOnboarding",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerShouldCompleteMemberOnboarding",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinCommunityPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationMembershipResponse",
        "kind": "LinkedField",
        "name": "createOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JoinCommunityPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationMembershipResponse",
        "kind": "LinkedField",
        "name": "createOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ad44ef5e720b2de30e5b1a15abd4f60",
    "id": null,
    "metadata": {},
    "name": "JoinCommunityPageMutation",
    "operationKind": "mutation",
    "text": "mutation JoinCommunityPageMutation(\n  $input: CreateOrganizationMembershipInput!\n) {\n  response: createOrganizationMembership(input: $input) {\n    node {\n      hasSeenOnboarding\n      viewerShouldCompleteMemberOnboarding\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4471a0d0a44f30d77a7c98013e65eed";

export default node;
