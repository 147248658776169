import { ProductAppKind } from "@/apps/remove-app-button/__generated__/RemoveAppButtonFragment.graphql"
import { APP_KIND_LABELS } from "@/apps/util/appConstants"
import { useLabels } from "@/core/context/LabelsContext"
import { capitalizeWord } from "@utils/string/stringUtils"

export function useAppLabel(productApp: {
  kind: ProductAppKind
  customAppTitle?: string | null
  feed?: {
    name: string
  } | null
}) {
  const labels = useLabels()
  if (productApp.kind === "content") {
    if (!productApp.customAppTitle) return "Content"
    return capitalizeWord(productApp.customAppTitle)
  }
  if (productApp.kind === "members") {
    return labels.product_member.plural
  }
  if (productApp.kind === "organization_members") {
    return labels.organization_member.plural
  }
  if (productApp.kind === "posts" && productApp.feed?.name) {
    return productApp.feed.name
  }
  if (productApp.kind === "collection") {
    if (!productApp.customAppTitle) return "Collection"
    return capitalizeWord(productApp.customAppTitle)
  }
  if (productApp.kind === "nav_folder") {
    if (!productApp.customAppTitle) return "Folder"
    return capitalizeWord(productApp.customAppTitle)
  }
  if (productApp.kind === "product_link") {
    return `${labels.admin_experience.singular} Link`
  }
  return APP_KIND_LABELS[productApp.kind] || "App"
}

export const INTEGRATION_URL_PLACEHOLDER = {
  airtable: "https://airtable.com/embed/shrXXXXXXXXXXX",
  google_docs: "https://docs.google.com/document/d/XXXXXXXXXXXX/edit",
  google_forms: "https://docs.google.com/forms/d/e/XXXXXXXXXXXX/viewshare",
  typeform: "https://abcd1234abc.typeform.com/to/XXXXXXXX",
  figma: "https://figma.com/file/XXXXXXXXXXXX",
  qr_code: "https://yoursite.com",
}

export const INTEGRATION_EMBED_PLACEHOLDER = {
  discord: `<iframe src="https://discord.com/widget?id=xxxxxxxxxxxxxxxxxx&theme=dark" width="100%" height="100%" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>`,
  whereby: `<iframe src="https://subdomain.whereby.com/room?minimal" width="100%" height="100%" allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"></iframe>`,
  miro: `<iframe width="100%" height="100%" src="https://miro.com/app/live-embed/xXxXXXxXxxx=/?moveToViewport=-xxxxx,-xxxxx,xxxxx,xxxxx&amp;embedId=xxxxxxxxxxxx" frameborder="0" scrolling="no" allowfullscreen></iframe>`,
  custom_embed: `<iframe src="https://yoursite.com" frameborder="0" width="100%" height="100%"></iframe>`,
  wistia: `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%"><iframe src="https://fast.wistia.net/embed/iframe/XXXXXXXXXX" title="XXXXXXXXXX" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>`,
}
