import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"

export type MobilePlatform = "ios" | "android"

export function useDownloadMobileAppLink() {
  const activeOrganization = useActiveOrganization()

  return {
    iosLink: `${activeOrganization?.primaryDomain}/mobile-downloads/ios`,
    androidLink: `${activeOrganization?.primaryDomain}/mobile-downloads/android`,
  }
}
