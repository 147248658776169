import Relay from "@/relay/relayUtils"
import { graphql } from "relay-runtime"
import {
  RegistrationSendVerificationEmailInput,
  useRegistrationSendVerificationEmailMutation,
} from "./__generated__/useRegistrationSendVerificationEmailMutation.graphql"
import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"

export function useRegistrationSendVerificationEmail(): (
  input: RegistrationSendVerificationEmailInput
) => Promise<boolean> {
  const mutation = Relay.useAsyncMutation<useRegistrationSendVerificationEmailMutation>(
    graphql`
      mutation useRegistrationSendVerificationEmailMutation(
        $input: RegistrationSendVerificationEmailInput!
      ) {
        registrationSendVerificationEmail(input: $input) {
          data
          errors {
            field
            message
          }
        }
      }
    `
  )

  async function registrationSendVerificationEmail(
    input: RegistrationSendVerificationEmailInput
  ): Promise<boolean> {
    const { registrationSendVerificationEmail: response } = await mutation({ input })
    if (response.errors) {
      displayGraphQLErrorToast(response.errors[0])
      return false
    }

    return true
  }

  return registrationSendVerificationEmail
}
