import useMarkNotificationsAsRead from "@/apps/util/hooks/useMarkNotificationsAsRead"
import {
  postWebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import {
  NotificationNode,
  useNotificationsContext,
} from "@/core/context/NotificationsContext"
import { useEffect } from "react"

type DismissOptions = {
  skip?: boolean
}

/**
 * A custom hook to mark the *target* notification(s) as read on render.
 * Ignore if no notifications are found or *opts.skip* is true
 */
function useDismissNotifications(
  target: Partial<NotificationNode>,
  opts: DismissOptions = {
    // Always dismiss the notifications by default
    skip: false,
  }
) {
  const { markAsRead } = useMarkNotificationsAsRead()
  const { getUnreadNotifications } = useNotificationsContext()

  useEffect(() => {
    if (opts.skip) return

    const notifications = getUnreadNotifications(target)

    if (!notifications.length) return

    // Resolve any unread notifications that matched the target
    markAsRead(notifications.map((n) => n.id))

    // Notify the mobile app to dismiss the notifications
    postWebViewMessage({
      type: WebViewMessageType.dismiss_notifications,
      notificationIds: notifications.map((n) => n.id),
    })
  }, [opts.skip, markAsRead, target, getUnreadNotifications])
}

export default useDismissNotifications
