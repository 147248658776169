import {
  NotificationFilter,
  useNotificationsContext,
} from "@/core/context/NotificationsContext"

function useUnreadNotifications(target: Partial<NotificationFilter> | null) {
  const { getUnreadNotifications } = useNotificationsContext()
  if (!target) return []
  return getUnreadNotifications(target)
}

export default useUnreadNotifications
