import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useConfettiCannon } from "@/core/context/ConfettiContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import Emoji from "a11y-react-emoji"
import { useEffect } from "react"

const TESTID = "JoinCommunityPageSuccessContent"

function JoinCommunityPageSuccessContent() {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const confettiCannon = useConfettiCannon()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => confettiCannon(), [])

  return (
    <div className={classes.container}>
      <Emoji symbol={"🎉"} label={"Party Popper"} className={classes.emoji} />
      <DiscoText
        variant={"heading-md"}
        marginTop={4}
        marginBottom={1}
        data-testid={`${TESTID}.success-title`}
      >
        {`Welcome to ${activeOrganization.name}!`}
      </DiscoText>
      <DiscoText className={classes.paragraph} data-testid={`${TESTID}.success-message`}>
        {`You have successfully joined the community.`}
      </DiscoText>

      {/* 
        Hard link cause we have to reset a lot of state after creating a membership.
        Much easier to reload than trying to keep it updated in this form. 
      */}
      <a
        href={ROUTE_NAMES.COMMUNITY.HOME.ROOT}
        className={classes.backToCommunityLink}
        data-testid={`${TESTID}.community-link`}
      >
        <DiscoText variant={"body-lg-600"} color={"primary.main"}>
          {"Explore the Community"}
        </DiscoText>
      </a>
    </div>
  )
}

export default JoinCommunityPageSuccessContent

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    paddingTop: theme.spacing(4),
  },
  emoji: {
    fontSize: "66px",
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    maxWidth: "350px",
  },
  backToCommunityLink: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(5),
  },
}))
