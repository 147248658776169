import Relay from "@/relay/relayUtils"
import { graphql } from "relay-runtime"
import {
  JoinCommunitySendVerificationEmailInput,
  useJoinCommunitySendVerificationEmailMutation,
} from "./__generated__/useJoinCommunitySendVerificationEmailMutation.graphql"
import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"

export function useJoinCommunitySendVerificationEmail(): (
  input: JoinCommunitySendVerificationEmailInput
) => Promise<boolean> {
  const mutation = Relay.useAsyncMutation<useJoinCommunitySendVerificationEmailMutation>(
    graphql`
      mutation useJoinCommunitySendVerificationEmailMutation(
        $input: JoinCommunitySendVerificationEmailInput!
      ) {
        joinCommunitySendVerificationEmail(input: $input) {
          data
          errors {
            field
            message
          }
        }
      }
    `
  )

  async function joinCommunitySentVerificationEmail(
    input: JoinCommunitySendVerificationEmailInput
  ): Promise<boolean> {
    const { joinCommunitySendVerificationEmail: response } = await mutation({ input })

    if (response.errors) {
      displayGraphQLErrorToast(response.errors[0])
      return false
    }
    return true
  }

  return joinCommunitySentVerificationEmail
}
