import { JoinCommunityFormStore } from "@/authentication/join-community/JoinCommunityForm"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { Grid, Theme, useMediaQuery } from "@material-ui/core"
import Emoji from "a11y-react-emoji"
import { FC } from "react"

interface Props {
  form: JoinCommunityFormStore
}

const JoinCommunityFormInvitationSection: FC<Props> = ({ form }) => {
  const classes = useStyles()
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  if (!form.state.invitation) return null
  const { invitation } = form.state

  return (
    <Grid
      container
      spacing={2}
      wrap={"nowrap"}
      data-testid={"JoinCommunityFormInvitationSection.container"}
      className={classes.container}
    >
      <Grid item>
        <Emoji symbol={"✨"} label={"Invitation decoration"} className={classes.emoji} />
      </Grid>
      <Grid item container alignItems={"center"}>
        <DiscoText
          variant={isXsScreen ? "body-lg-600" : "heading-md"}
          testid={"JoinCommunityFormInvitationSection.message"}
        >
          {getInvitationHeaderText()}
        </DiscoText>
      </Grid>
    </Grid>
  )

  function getInvitationHeaderText() {
    switch (invitation.kind) {
      case "member":
        return `You have been invited to join this community!`
      default:
        return ""
    }
  }
}

export default JoinCommunityFormInvitationSection

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  emoji: {
    [theme.breakpoints.up("md")]: {
      fontSize: "85px",
      lineHeight: "85px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "66px",
      lineHeight: "66px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "48px",
      lineHeight: "48px",
    },
  },
}))
