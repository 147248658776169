/**
 * @generated SignedSource<<35148b0d4d03fcdef87d3069d6ab6a93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarkNotificationsAsReadInput = {
  ids: ReadonlyArray<string>;
};
export type useMarkNotificationsAsReadMutation$variables = {
  input: MarkNotificationsAsReadInput;
};
export type useMarkNotificationsAsReadMutation$data = {
  readonly response: {
    readonly data: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly readAt: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"NotificationListItemTemplateFragment">;
        };
      }>;
    } | null;
  };
};
export type useMarkNotificationsAsReadMutation = {
  variables: useMarkNotificationsAsReadMutation$variables;
  response: useMarkNotificationsAsReadMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMarkNotificationsAsReadMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "MarkNotificationsAsReadResponse",
        "kind": "LinkedField",
        "name": "markNotificationsAsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationNodeConnection",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Notification",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NotificationListItemTemplateFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMarkNotificationsAsReadMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "MarkNotificationsAsReadResponse",
        "kind": "LinkedField",
        "name": "markNotificationsAsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationNodeConnection",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Notification",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cbc27b2c97d90294cd899e5553024bbb",
    "id": null,
    "metadata": {},
    "name": "useMarkNotificationsAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation useMarkNotificationsAsReadMutation(\n  $input: MarkNotificationsAsReadInput!\n) {\n  response: markNotificationsAsRead(input: $input) {\n    data {\n      edges {\n        node {\n          id\n          readAt\n          ...NotificationListItemTemplateFragment\n        }\n      }\n    }\n  }\n}\n\nfragment NotificationListItemTemplateFragment on Notification {\n  createdAt\n  kind\n  readAt\n  product {\n    name\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eadd821298ec5da317ec89940edc2ad2";

export default node;
