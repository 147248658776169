import { useFetchOrganizationSlackWorkspaceUrlQuery } from "@/apps/util/hooks/__generated__/useFetchOrganizationSlackWorkspaceUrlQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { graphql, useLazyLoadQuery } from "react-relay"

function useFetchOrganizationSlackWorkspaceUrl() {
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()!

  const { organization, product } =
    useLazyLoadQuery<useFetchOrganizationSlackWorkspaceUrlQuery>(
      graphql`
        query useFetchOrganizationSlackWorkspaceUrlQuery(
          $productId: ID!
          $organizationId: ID!
        ) {
          organization: node(id: $organizationId) {
            id
            ... on Organization {
              slack {
                workspace {
                  url
                }
              }
            }
          }
          product: node(id: $productId) {
            ... on Product {
              slack {
                inviteURL
              }
            }
          }
        }
      `,
      { productId: activeProduct?.id || "", organizationId: activeOrganization.id }
    )

  return product?.slack?.inviteURL || organization?.slack?.workspace?.url
}

export default useFetchOrganizationSlackWorkspaceUrl
