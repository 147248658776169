import { useLogoutMutation } from "@/authentication/logout/util/__generated__/useLogoutMutation.graphql"
import Relay from "@/relay/relayUtils"
import { graphql } from "relay-runtime"

export function useLogout(dontReload?: boolean): () => Promise<void> {
  const mutation = Relay.useAsyncMutation<useLogoutMutation>(
    graphql`
      mutation useLogoutMutation {
        logout {
          data
        }
      }
    `
  )

  async function logout() {
    await mutation({})
    if (!dontReload) {
      window.location.reload()
    }
  }

  return logout
}
