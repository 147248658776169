import { useMarkNotificationsAsReadAllMutation } from "@/apps/util/hooks/__generated__/useMarkNotificationsAsReadAllMutation.graphql"
import { useMarkNotificationsAsReadMutation } from "@/apps/util/hooks/__generated__/useMarkNotificationsAsReadMutation.graphql"
import {
  useActiveOrganization,
  ActiveOrganizationContextValue,
} from "@/core/context/ActiveOrganizationContext"
import { useConfettiCannon } from "@/core/context/ConfettiContext"
import { useNotificationsContext } from "@/core/context/NotificationsContext"
import { sendSentryAnException } from "@/core/sentryHandler"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { useCallback } from "react"
import { graphql } from "react-relay"
import { RecordSourceProxy } from "relay-runtime"
import ConnectionHandler from "relay-connection-handler-plus"

function useMarkNotificationsAsRead() {
  const activeOrganization = useActiveOrganization()!
  const { refetch: refetchNotifications } = useNotificationsContext()
  const confettiCannon = useConfettiCannon()
  const markNotificationsAsRead =
    Relay.useAsyncMutation<useMarkNotificationsAsReadMutation>(
      graphql`
        mutation useMarkNotificationsAsReadMutation(
          $input: MarkNotificationsAsReadInput!
        ) {
          response: markNotificationsAsRead(input: $input) {
            data {
              edges {
                node {
                  id
                  readAt
                  ...NotificationListItemTemplateFragment
                }
              }
            }
          }
        }
      `
    )

  const markAllNotificationsAsRead =
    Relay.useAsyncMutation<useMarkNotificationsAsReadAllMutation>(
      graphql`
        mutation useMarkNotificationsAsReadAllMutation(
          $input: MarkAllNotificationsAsReadInput!
        ) {
          response: markAllNotificationsAsRead(input: $input) {
            success
          }
        }
      `
    )

  /** Mark all notifications as read */
  const markAllAsRead = useCallback(async () => {
    try {
      await markAllNotificationsAsRead(
        {
          input: {
            organizationId: activeOrganization.id,
          },
        },
        {
          updater: (store) => {
            updateRelayNotifications(store, activeOrganization)
          },
          optimisticUpdater: (store) => {
            updateRelayNotifications(store, activeOrganization)
          },
        }
      )
      await refetchNotifications()
      confettiCannon()
      displaySuccessToast({
        message: "Notifications marked as read!",
      })
    } catch (err) {
      sendSentryAnException(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization.id])

  /** Mark notifications as read */
  const markAsRead = useCallback(async (ids: GlobalID[]) => {
    try {
      await markNotificationsAsRead({
        input: {
          ids,
        },
      })
      await refetchNotifications()
    } catch (err) {
      sendSentryAnException(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    markAsRead,
    markAllAsRead,
  }
}

function updateRelayNotifications(
  store: RecordSourceProxy,
  activeOrganization: ActiveOrganizationContextValue
) {
  const organizationRecord = store.get(activeOrganization.id)
  if (!organizationRecord) return
  ConnectionHandler.getConnections(
    organizationRecord,
    "NotificationList__viewerNotifications"
  ).forEach((connection) => {
    const edges = connection.getLinkedRecords("edges")
    edges?.forEach((edge) => {
      const node = edge.getLinkedRecord("node")
      if (!node) return
      const readAt = node.getValue("readAt")
      if (!readAt) node.setValue(new Date().toISOString(), "readAt")
    })
  })
}

export default useMarkNotificationsAsRead
