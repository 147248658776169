import { useSearchClientFragment$key } from "@/apps/util/hooks/__generated__/useSearchClientFragment.graphql"
import { useSearchClientRefetchQuery } from "@/apps/util/hooks/__generated__/useSearchClientRefetchQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { useMemo } from "react"
import { useRefetchableFragment } from "react-relay"
import { graphql } from "relay-runtime"
import TypesenseInstantSearchAdapter, {
  TypesenseInstantsearchAdapterOptions,
} from "typesense-instantsearch-adapter"

export default function useSearchClient() {
  const activeOrganization = useActiveOrganization()
  const { globalSearch } = useFeatureFlags()
  const subscriptionStanding = useSubscriptionStanding()

  const [organization, refetch] = useRefetchableFragment<
    useSearchClientRefetchQuery,
    useSearchClientFragment$key
  >(
    graphql`
      fragment useSearchClientFragment on Organization
      @refetchable(queryName: "useSearchClientRefetchQuery") {
        id
        searchConfig
      }
    `,
    activeOrganization || null
  )

  const searchConfig = organization?.searchConfig

  const { searchClient } = useMemo(() => {
    if (!searchConfig) return { searchClient: null }
    return new TypesenseInstantSearchAdapter(
      JSON.parse(searchConfig) as TypesenseInstantsearchAdapterOptions
    )
  }, [searchConfig])

  if (
    !globalSearch ||
    !organization ||
    !searchClient ||
    subscriptionStanding !== "active"
  )
    return null

  const indexName = Relay.fromGlobalId(organization.id).id

  const refreshSearch = () => {
    refetch({}, { fetchPolicy: "network-only" })
  }

  return { searchClient, indexName, refreshSearch }
}
