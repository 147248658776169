import JoinCommunityForm, {
  JoinCommunityFormState,
} from "@/authentication/join-community/JoinCommunityForm"
import JoinCommunityPageSuccessContent from "@/authentication/join-community/JoinCommunityPageSuccessContent"
import OrganizationOnboardingInfoSection from "@/authentication/join-community/OrganizationOnboardingInfoSection"
import { JoinCommunityPageMutation } from "@/authentication/join-community/__generated__/JoinCommunityPageMutation.graphql"
import { JoinCommunityPageQuery } from "@/authentication/join-community/__generated__/JoinCommunityPageQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { generateMetaTags } from "@/main/page/MetaDetails"
import OnboardingPage from "@/main/page/OnboardingPage"
import Relay from "@/relay/relayUtils"
import { DiscoSpinner } from "@disco-ui"
import { useQueryParams, useReplacePathname } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { Helmet } from "react-helmet-async"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export type JoinCommunityPageParams = {
  email?: string
  inviteToken?: string
  inviteKey?: string
}

function JoinCommunityPage() {
  const activeOrganization = useActiveOrganization()!
  const { authUser } = useAuthUser()
  const { email, inviteToken, inviteKey } = useQueryParams<JoinCommunityPageParams>()

  const { organization } = useLazyLoadQuery<JoinCommunityPageQuery>(
    graphql`
      query JoinCommunityPageQuery($id: ID!, $inviteToken: String, $inviteKey: String) {
        organization: node(id: $id) {
          ... on Organization {
            id
            questions {
              edges {
                node {
                  id
                  body
                  richEditorBody
                }
              }
            }
            name
            description
            cover
            marketingPage {
              metaTitle
              metaDescription
              metaImageUrl
            }
            viewerInvitation(token: $inviteToken) {
              email
              kind
              firstName
              lastName
            }
            isInviteKeyValid(key: $inviteKey)
            ...OrganizationOnboardingInfoSectionFragment
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      inviteToken,
      inviteKey,
    }
  )

  const organizationQuestions = Relay.connectionToArray(organization?.questions)
  const marketingPage = organization?.marketingPage
  const invitation = organization?.viewerInvitation

  const form = useFormStore<JoinCommunityPageMutation, JoinCommunityFormState>(
    graphql`
      mutation JoinCommunityPageMutation($input: CreateOrganizationMembershipInput!) {
        response: createOrganizationMembership(input: $input) {
          node {
            hasSeenOnboarding
            viewerShouldCompleteMemberOnboarding
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      organizationForm: organizationQuestions.map((q) => ({
        questionId: q.id,
        answer: "",
      })),
      step: "registration",
      hasAcceptedTerms: authUser?.hasAcceptedTerms || false,
      newUser: authUser
        ? null
        : {
            firstName: invitation?.firstName || "",
            lastName: invitation?.lastName || "",
            email: invitation?.email || email || "",
            confirmEmail: invitation?.email || email || "",
          },
      questions: organizationQuestions,
      // The viewer's invitation, either from decoding the invite token or a pending invitation that matches the user's email
      invitation,
      inviteToken: inviteToken || null,
      inviteKey: inviteKey || null,
      usingEmail: false,
      hasSeenOnboarding: false,
      isInviteKeyValid: organization?.isInviteKeyValid || false,
      viewerShouldCompleteMemberOnboarding: false,
      needsEmailVerification: false,
    }
  )

  // Update pathname to signal progress to analytics.
  useReplacePathname(getDesiredPathForAnalytics())

  if (form.state.step === "success") {
    if (
      !form.state.hasSeenOnboarding &&
      form.state.viewerShouldCompleteMemberOnboarding
    ) {
      // send the user to member onboarding if it exists and they haven't seen it yet
      location.href = ROUTE_NAMES.COMMUNITY.HOME.ROOT
      return <DiscoSpinner />
    }

    return (
      <OnboardingPage>
        <JoinCommunityPageSuccessContent />
      </OnboardingPage>
    )
  }

  if (!organization) return null

  return (
    <>
      <Helmet
        meta={generateMetaTags({
          title: marketingPage?.metaTitle || organization.name || undefined,
          description:
            marketingPage?.metaDescription || organization.description || undefined,
          coverPhoto: marketingPage?.metaImageUrl || organization.cover || undefined,
          url: window.location.href,
        })}
      />
      <OnboardingPage
        leftContent={<OrganizationOnboardingInfoSection organizationKey={organization} />}
        rightContent={<JoinCommunityForm form={form} />}
      />
    </>
  )

  function getDesiredPathForAnalytics() {
    switch (form.state.step) {
      case "success":
        return ROUTE_NAMES.COMMUNITY.JOIN.SUCCESS
      default:
        return ROUTE_NAMES.COMMUNITY.JOIN.ROOT
    }
  }
}

export default observer(JoinCommunityPage)
