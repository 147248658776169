/** A context that exposes if its children relate are specific to a product or a community. */

import {
  appLevelContextFragment$data,
  appLevelContextFragment$key,
} from "@/apps/util/__generated__/appLevelContextFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import React, { useContext } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type AppLevelContextValue = {
  product?: appLevelContextFragment$data
  navSectionId?: GlobalID
  navFolderId?: GlobalID | null
}

const AppLevelContext = React.createContext({} as AppLevelContextValue)

export function useAppLevel() {
  return useContext(AppLevelContext)
}

type AppLevelProviderProps =
  // Product-level app props
  | {
      productKey: appLevelContextFragment$key | null
      navFolderId?: GlobalID | null
      navSectionId?: undefined
    }
  // Organization-level app props
  | {
      productKey?: undefined
      navFolderId?: undefined
      navSectionId?: GlobalID
    }

export const AppLevelProvider: React.FC<AppLevelProviderProps> = ({
  productKey,
  navSectionId,
  navFolderId,
  children,
}) => {
  const product = useFragment<appLevelContextFragment$key>(
    graphql`
      fragment appLevelContextFragment on Product {
        id
        name
        slug
        type
        ...useConnectedProductAppsActiveProductFragment
      }
    `,
    productKey || null
  )

  return (
    <AppLevelContext.Provider
      value={{
        product: product || undefined,
        navSectionId,
        navFolderId,
      }}
    >
      {children}
    </AppLevelContext.Provider>
  )
}
