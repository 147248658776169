/**
 * @generated SignedSource<<fd80e7a88869c857fdc11bd4b2e8fbf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type appLevelContextFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly type: ProductType;
  readonly " $fragmentSpreads": FragmentRefs<"useConnectedProductAppsActiveProductFragment">;
  readonly " $fragmentType": "appLevelContextFragment";
};
export type appLevelContextFragment$key = {
  readonly " $data"?: appLevelContextFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appLevelContextFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appLevelContextFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useConnectedProductAppsActiveProductFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "531f43fd1d1a9bb93dc60b13ac7f56a3";

export default node;
