import SlackProductConnectionSettingsButton from "@/apps/list/app/slack/settings-button/SlackChannelProductAppSettingsButton"
import AppSidebarItem from "@/apps/sidebar-item/AppSidebarItem"
import { AppSidebarItemFragment$key } from "@/apps/sidebar-item/__generated__/AppSidebarItemFragment.graphql"
import useFetchOrganizationSlackWorkspaceUrl from "@/apps/util/hooks/useFetchOrganizationSlackWorkspaceUrl"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import { DiscoSideBarItemSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import React from "react"

interface Props {
  testid: string
  appKey: AppSidebarItemFragment$key
  hideLeftIcon?: boolean
  isDragging?: boolean
}

const SlackProductAppSidebarItem: React.FC<Props> = ({
  testid,
  appKey,
  hideLeftIcon,
  isDragging,
}) => {
  const permissions = useActiveOrganization()?.viewerPermissions

  const slackWorkspaceUrl = useFetchOrganizationSlackWorkspaceUrl()
  if (!slackWorkspaceUrl) return null

  return (
    <AppSidebarItem
      testid={`${testid}.Slack`}
      hideLeftIcon={hideLeftIcon}
      externalLink={slackWorkspaceUrl}
      appKey={appKey}
      settingsButton={
        permissions?.has("integrations.manage") && (
          <SlackProductConnectionSettingsButton key={"settings"}>
            {(props) => (
              <DiscoDropdownItem
                data-testid={`${testid}.Slack.more-actions.settings`}
                {...props}
                title={"Settings"}
              />
            )}
          </SlackProductConnectionSettingsButton>
        )
      }
      isDragging={isDragging}
    />
  )
}
export default Relay.withSkeleton<Props>({
  component: SlackProductAppSidebarItem,
  skeleton: DiscoSideBarItemSkeleton,
})
