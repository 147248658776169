import { useSearchContext } from "@/core/context/SearchContext"
import { useTheme } from "@material-ui/core"

/** Highlights the matching strings to the search query */
export default function useSearchHighlight() {
  const { query } = useSearchContext()
  const theme = useTheme()

  return {
    highlight: (text: string) => {
      if (!query) return text
      // create a regex that includes invalid characters for a search query
      const regex = new RegExp(`(${query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`, "gi")
      return text.replace(
        regex,
        (match) =>
          `<span style="background: ${
            theme.palette.type === "dark"
              ? theme.palette.groovy.blue[400]
              : theme.palette.groovy.blue[200]
          }
          !important">${match}</span>`
      )
    },
  }
}
