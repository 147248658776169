import {
  LegacyCreateSetupAppModalFormState,
  LegacyCreateSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { useAppLevel } from "@/apps/util/appLevelContext"
import {
  CreateProductAppInput,
  useAddAppFormStoreMutation,
} from "@/apps/util/hooks/__generated__/useAddAppFormStoreMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { graphql } from "relay-runtime"

function useAddAppFormStore(
  input: Omit<CreateProductAppInput, "productId" | "organizationId">
): LegacyCreateSetupAppModalFormStore {
  const { product, navSectionId } = useAppLevel()
  const activeOrganization = useActiveOrganization()!

  const form = useFormStore<
    useAddAppFormStoreMutation,
    LegacyCreateSetupAppModalFormState
  >(
    graphql`
      mutation useAddAppFormStoreMutation(
        $input: CreateProductAppInput!
        $connections: [ID!]!
        $isOrgTopLevel: Boolean = false
      ) {
        response: createProductApp(input: $input) {
          node {
            id
            kind
            status
            ordering
            customAppTitle
            customAppUrl
            visibility
            showOnDashboard
            ...AppSidebarItemFragment
            ...FeedAppSettingsButtonFragment
            navSectionId
            feed @appendNode(connections: $connections, edgeTypeName: "FeedNodeEdge") {
              id
            }
            collection {
              id
            }
            badge {
              kind
              color
              emoji
              mediaUrl
            }
            product {
              id
              slug
              ...MyExperiencesListItemFragment
              ...AppsSidebarList_ProductFragment
              dashboard {
                id
                blocks(position: main) {
                  edges {
                    node {
                      id
                      kind
                      ...DashboardBlockItemFragment
                    }
                  }
                }
              }
            }
            organization @include(if: $isOrgTopLevel) {
              ...AppsSidebarList_OrganizationFragment
            }
            navSection {
              ...AppsSidebarList_NavSectionFragment
            }
            contentUsage {
              id
            }
            ...useRenderProductAppItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      ...input,
      productId: product?.id,
      navSectionId,
      organizationId: activeOrganization.id,
      showOnDashboard: true,
    }
  )
  return form
}

export default useAddAppFormStore
