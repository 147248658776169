import DiscoLogo from "@/core/ui/images/disco-logo-light.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PageContent from "@/main/page/content/PageContent"
import MetaDetails, { MetaDetailsProps } from "@/main/page/MetaDetails"
import { DiscoSection } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"

type AuthenticationPageProps = {
  shouldShowLogo?: boolean
  shouldShowUserAgreement?: boolean
  hideBackground?: boolean
  disableContainer?: boolean
  rightSideContent?: JSX.Element
  classes?: Partial<
    ClassNameMap<
      | "section"
      | "content"
      | "pageContainer"
      | "pageContentMain"
      | "main"
      | "contentContainer"
    >
  >
} & MetaDetailsProps

const AuthenticationPage: React.FC<AuthenticationPageProps> = (props) => {
  const {
    shouldShowLogo = false,
    hideBackground = false,
    disableContainer = false,
    rightSideContent,
    classes: customClasses,
    ...rest
  } = props
  const classes = useStyles()

  return (
    <MetaDetails {...rest}>
      {!hideBackground && <div className={classes.background} />}
      <PageContent
        classes={{
          container: customClasses?.pageContainer,
          main: customClasses?.pageContentMain,
        }}
        customClassName={classNames(classes.content, customClasses?.content)}
      >
        <DiscoSection
          className={
            disableContainer
              ? classes.disabledSection
              : classNames(classes.section, customClasses?.section)
          }
        >
          <div className={classNames(customClasses?.contentContainer)}>
            {shouldShowLogo && <DiscoLogo className={classes.logo} />}
            {props.children}
          </div>
        </DiscoSection>
        {rightSideContent}
      </PageContent>
    </MetaDetails>
  )
}

const useStyles = makeUseStyles((theme) => ({
  background: {
    position: "fixed",
    width: "100%",
    height: "100%",
    background: `radial-gradient(at right 100%, #2D44A1 10.39%, #4467F6 57.03%)`,
  },
  content: {
    position: "relative",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    margin: theme.spacing(5, "auto"),
    display: "block",
    maxWidth: 270,
    maxHeight: 65,
    width: "100%",
  },
  disabledSection: {
    background: "none",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 0, 0),
    },
  },
  section: {
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.measure.borderRadius.large,
    boxShadow: theme.palette.groovyDepths.insideCard,
    width: "100%",
    maxWidth: "640px",
    margin: "auto",
    maxHeight: "940px",
    padding: theme.spacing(5.5, 7.5, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2.5, 3, 2),
    },
  },
}))
export default AuthenticationPage
