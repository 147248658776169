import { GlobalID } from "@/relay/RelayTypes"
import { ConnectionHandler } from "relay-runtime"

namespace AppUtils {
  export function getFeedConnections(
    organizationId: GlobalID | null | undefined,
    productId: GlobalID | null | undefined
  ) {
    const connections = []

    if (organizationId) {
      connections.push(
        ConnectionHandler.getConnectionID(
          organizationId,
          "OrganizationFeedSelectorDropdown_feeds"
        )
      )
    }

    if (productId) {
      connections.push(
        ConnectionHandler.getConnectionID(productId, "ProductFeedSelectorDropdown_feeds")
      )
    }
    return connections
  }

  export function getAppConnectionIds(
    organizationId: GlobalID,
    productId: GlobalID | null | undefined,
    navSectionId: GlobalID | null | undefined
  ) {
    if (productId)
      return [
        ConnectionHandler.getConnectionID(
          productId,
          "AppsSidebarList_ProductFragment__productApps"
        ),
      ]
    if (navSectionId)
      return [
        ConnectionHandler.getConnectionID(
          navSectionId,
          "AppsSidebarList_NavSectionFragment__apps"
        ),
      ]
    return [
      ConnectionHandler.getConnectionID(
        organizationId,
        "AppsSidebarList_OrganizationFragment__apps"
      ),
    ]
  }
}

export default AppUtils
