import { useAppLevel } from "@/apps/util/appLevelContext"
import { useConnectedProductAppsActiveProductFragment$key } from "@/apps/util/hooks/__generated__/useConnectedProductAppsActiveProductFragment.graphql"
import Relay from "@/relay/relayUtils"
import { useMemo } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export default function useConnectedProductApps(
  productKey?: useConnectedProductAppsActiveProductFragment$key | null
) {
  const { product: appLevelProduct } = useAppLevel()
  const product = useFragment<useConnectedProductAppsActiveProductFragment$key>(
    graphql`
      fragment useConnectedProductAppsActiveProductFragment on Product {
        connectedApps: productApps(includeNested: true) {
          edges {
            node {
              id
              kind
            }
          }
        }
      }
    `,
    productKey || appLevelProduct || null
  )

  const connectedApps = useMemo(
    () => new Set(Relay.connectionToArray(product?.connectedApps).map((a) => a.kind)),
    [product?.connectedApps]
  )

  return { connectedApps }
}
