/**
 * @generated SignedSource<<bfa716bd45563d9501d7619468146eab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReorderAppInput = {
  appId: string;
  ordering: number;
  navSectionId?: string | null;
  navFolderId?: string | null;
};
export type AppsSidebarDragDropProvider_ReorderAppMutation$variables = {
  input: ReorderAppInput;
};
export type AppsSidebarDragDropProvider_ReorderAppMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly navSectionId: string | null;
      readonly ordering: number;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type AppsSidebarDragDropProvider_ReorderAppMutation = {
  variables: AppsSidebarDragDropProvider_ReorderAppMutation$variables;
  response: AppsSidebarDragDropProvider_ReorderAppMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderAppResponse",
    "kind": "LinkedField",
    "name": "reorderApp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductApp",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "navSectionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordering",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppsSidebarDragDropProvider_ReorderAppMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppsSidebarDragDropProvider_ReorderAppMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "44cfa79c68a63bae4c8640eda8a61c08",
    "id": null,
    "metadata": {},
    "name": "AppsSidebarDragDropProvider_ReorderAppMutation",
    "operationKind": "mutation",
    "text": "mutation AppsSidebarDragDropProvider_ReorderAppMutation(\n  $input: ReorderAppInput!\n) {\n  response: reorderApp(input: $input) {\n    node {\n      id\n      navSectionId\n      ordering\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d6284f52a56ba3ddd18b8bcf2806e78";

export default node;
