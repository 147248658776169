import AuthenticationFlowContentBox from "@/authentication/common/flow-content-box/AuthenticationFlowContentBox"
import AuthenticationPage from "@/authentication/common/page/AuthenticationPage"
import LoadingPage from "@components/loading-page/LoadingPage"
import { DiscoText } from "@disco-ui"
import { useEffect } from "react"
import { useCompleteSSOLogin } from "./util/useCompleteSSOLogin"

function SSORedirectPage() {
  const { completeSSOLogin, status } = useCompleteSSOLogin()

  useEffect(() => {
    completeSSOLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {status.state === "error" ? (
        <AuthenticationPage hideBackground disableContainer metaTitle={"Logging in...."}>
          <AuthenticationFlowContentBox customClassName={"verify-email-box"}>
            <DiscoText marginTop={4} marginBottom={4} variant={"heading-md"}>
              {status.message ||
                "An error occurred when logging you in, please try again later [099]."}
            </DiscoText>
          </AuthenticationFlowContentBox>
        </AuthenticationPage>
      ) : (
        <LoadingPage />
      )}
    </>
  )
}

export default SSORedirectPage
